
  import {isHttpUrl} from '@/helper/ImageHelper';
  import ScreenSize from '@/mixins/ScreenSize';
  import Carousel from '@/mixins/Carousel';

  export default {
    mixins: [ScreenSize, Carousel],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      sliderElements: {
        type: Array,
        required: true,
      },
      numberOfElements: {
        type: String,
        required: true,
        default: 'three_3',
      },
      showSelect: {
        type: Boolean,
        required: false,
        default: true,
      },
      label: {
        type: String,
        required: true,
      },
      showSelectLink: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        selectedElement: null,
        elementsInDropdown: this.sliderElements,
        slides: [],
      };
    },
    computed: {
      numberOfColumns() {
        const numberOfElements = this.getNumberOfElementsInSlide(
          this.numberOfElements
        );
        let colNumber = 0;
        if (
          (numberOfElements === '2' && this.isDesktopOrTablet) ||
          this.$vuetify.breakpoint.width <= 610
        ) {
          colNumber = 6;
        } else if (numberOfElements === '4' && this.isDesktopOrTablet) {
          colNumber = 3;
        } else if (
          (numberOfElements === '3' && this.isDesktopOrTablet) ||
          this.isMobile
        ) {
          colNumber = 4;
        } else if (numberOfElements === '6' && this.isDesktopOrTablet) {
          colNumber = 2;
        }
        return colNumber;
      },
      elementNumber() {
        const elementsPerSlide = this.getNumberOfElementsInSlide(
          this.numberOfElements
        );
        if (this.$vuetify.breakpoint.width <= 610) {
          return 2;
        } else if (
          this.$vuetify.breakpoint.width > 610 &&
          this.$vuetify.breakpoint.width <= 960
        ) {
          return 3;
        } else {
          return elementsPerSlide;
        }
      },
    },
    watch: {
      '$vuetify.breakpoint.width'() {
        this.setSlides();
      },
    },
    created() {
      this.npm = this.elementsInDropdown.sort((a, b) =>
        a.text.localeCompare(b.text)
      );
    },
    mounted() {
      this.setSlides();
    },
    methods: {
      getNumberOfElementsInSlide() {
        const number = this.numberOfElements.split('_');
        if (number.length > 1) {
          return number[1];
        }
      },
      imageUrl(url) {
        return isHttpUrl(url) ? url : require(`@/assets/${url}`);
      },
      redirectToElementPage() {
        this.$router.push(this.localePath(this.selectedElement.link));
      },
      seeAll() {
        this.$router.push(
          this.localePath({
            name: this.showSelectLink,
          })
        );
      },
    },
  };
